import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';

import InputItem from '../Forms/InputItem/InputItem';
import FormButton from '../Forms/FormButton/FormButton';
import CustomSelect from '../Forms/CustomSelect/CustomSelect';
import Fader from '../Loaders/Fader';

import { USER_ROLES } from '../../../util/constants/AppConstants';
import { setNotify } from '../../../datastore/actions/actionActions';
import { createAdmin, createUser, deleteAdmin, saveUser, updateAdmin } from '../../../http/usersAPI';
import useOutsideClick from '../../../util/useOutsideClick';

import UsernameIcon from '../../../assets/images/icons/user.svg';
import PasswordIcon from '../../../assets/images/icons/password.svg';
import EngagementIcon from '../../../assets/images/icons/engagement.svg';
import JobIcon from '../../../assets/images/icons/job.svg';
import EmailIcon from '../../../assets/images/icons/email.svg';
import CityIcon from '../../../assets/images/icons/city.svg';
import StateIcon from '../../../assets/images/icons/state.svg';
import CountryIcon from '../../../assets/images/icons/globe.svg';
import UserRoleIcon from '../../../assets/images/icons/role.svg';
import './UserAdd.scss';

const UserAdd = ({ isOpen, onSaveHandler, onCloseHandler, userData }) => {
  const dispatch = useDispatch();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [city, setCity] = useState('');
  const [locationState, setLocationState] = useState('');
  const [country, setCountry] = useState('');
  const [userRole, setUserRole] = useState('');
  const [password, setPassword] = useState('');
  const [usersLimit, setUsersLimit] = useState(2);
  const [topicsPermission, setTopicsPermission] = useState(true);
  const [groupsPermission, setGroupsPermission] = useState(true);
  const [trendsPermission, setTrendsPermission] = useState(true);
  const [districtPermission, setDistrictPermission] = useState(true);
  const [mpPermission, setMpPermission] = useState(true);
  const [saving, setSaving] = useState(false);

  const config = useSelector((state) => state.config.config);

  const windowRef = useRef();

  const adminRoleValue = USER_ROLES.find((role) => role.short === 'Admin').value;

  useEffect(() => {
    if (userData) {
      setFirstname(userData.firstname);
      setLastname(userData.lastname);
      setEmail(userData.email);
      setPhone(userData.phone);
      setJobTitle(userData.job_title);
      setLocationState(userData.location_state);
      setCity(userData.location_city);
      setCountry(userData.location_country);
      setUserRole(userData.role);

      if (userData.role === adminRoleValue) {
        setUsersLimit(userData.permissions.users_limit);
        setTopicsPermission(userData.permissions.topics_permission);
        setGroupsPermission(userData.permissions.groups_permission);
        setTrendsPermission(userData.permissions.trends_permission);
        setDistrictPermission(userData.permissions.district_permission);
        setMpPermission(userData.permissions.mp_permission);
      }
    }
  }, [userData]);

  const checkIsValid = () => {
    if (firstname && lastname && email && phone && jobTitle && city && locationState && country && userRole) {
      return true;
    }

    return false;
  };

  const handleSuccess = (message) => {
    dispatch(
      setNotify({
        status: true,
        type: 'success',
        title: 'Success',
        message,
      }),
    );

    onSaveHandler();
  };

  const handleSave = async () => {
    if (checkIsValid()) {
      setSaving(true);

      const res = await saveUser(
        config,
        userData.id,
        firstname,
        lastname,
        email,
        phone,
        jobTitle,
        city,
        locationState,
        country,
        userRole,
        password,
      );

      if (res.status === 'success') {
        if (userRole === adminRoleValue && userData.role === adminRoleValue) {
          const res2 = await updateAdmin(
            config,
            userData.id,
            usersLimit,
            topicsPermission,
            groupsPermission,
            trendsPermission,
            districtPermission,
            mpPermission,
          );

          if (res2.status === 200) {
            handleSuccess(res.message);
          } else {
            console.log('ERROR: Updating Admin Permissions');
          }
        } else if (userRole === adminRoleValue && userData.role !== adminRoleValue) {
          const res2 = await createAdmin(
            config,
            userData.id,
            usersLimit,
            topicsPermission,
            groupsPermission,
            trendsPermission,
            districtPermission,
            mpPermission,
          );

          if (res2.status === 201) {
            handleSuccess(res.message);
          } else {
            console.log('ERROR: Setting Admin Permissions');
          }
        } else if (userRole !== adminRoleValue && userData.role === adminRoleValue) {
          const res2 = await deleteAdmin(config, userData.id);

          if (res2.status === 200) {
            handleSuccess(res.message);
          } else {
            console.log('ERROR: Deleting Admin Permissions');
          }
        } else {
          handleSuccess(res.message);
        }
      } else {
        dispatch(
          setNotify({
            status: true,
            type: 'error',
            title: 'Error',
            message: res.message,
          }),
        );
      }
      setSaving(false);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: 'Fill in all required fields',
        }),
      );
    }
  };

  const handleCreate = async () => {
    if (checkIsValid() && password) {
      setSaving(true);

      const res = await createUser(
        config,
        firstname,
        lastname,
        email,
        phone,
        password,
        jobTitle,
        city,
        locationState,
        country,
        userRole,
      );

      if (res.status === 'success') {
        if (userRole === adminRoleValue) {
          const res2 = await createAdmin(
            config,
            res.data[0].id,
            usersLimit,
            topicsPermission,
            groupsPermission,
            trendsPermission,
            districtPermission,
            mpPermission,
          );

          if (res2.status === 201) {
            handleSuccess(res.message);
          } else {
            console.log('ERROR: Setting Admin Permissions');
          }
        } else {
          handleSuccess(res.message);
        }
      } else {
        dispatch(
          setNotify({
            status: true,
            type: 'error',
            title: 'Error',
            message: res.message,
          }),
        );
      }
      setSaving(false);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: 'Fill in all the fields',
        }),
      );
    }
  };

  useOutsideClick(windowRef, null, isOpen, onCloseHandler);

  return (
    <div className="login-wrap user-add-wrap">
      <div className="login-view">
        <div ref={windowRef} className="login-box add-user-box">
          {!saving ? (
            <div className="logo-large">{userData ? 'Edit User' : 'Create A New User'}</div>
          ) : (
            <>
              <div className="logo-large">{userData ? 'Saving User Information' : 'Creating User'}</div>
              <div className="logo-sub user-space">
                The process should take no longer than 30 seconds. Please Wait...
              </div>
            </>
          )}
          {!saving ? (
            <>
              <div className="add-user-left">
                <InputItem
                  label="First Name"
                  type="text"
                  icon={UsernameIcon}
                  onChange={(e) => setFirstname(e.target.value)}
                  value={firstname}
                />
                <InputItem
                  label="Last Name"
                  type="text"
                  icon={UsernameIcon}
                  onChange={(e) => setLastname(e.target.value)}
                  value={lastname}
                />
                <InputItem
                  label="Email"
                  type="email"
                  icon={EmailIcon}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <InputItem
                  label="Password"
                  type="password"
                  icon={PasswordIcon}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <InputItem
                  label="Phone"
                  type="text"
                  icon={EngagementIcon}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </div>
              <div className="add-user-right">
                <InputItem
                  label="Job Title"
                  type="text"
                  icon={JobIcon}
                  onChange={(e) => setJobTitle(e.target.value)}
                  value={jobTitle}
                />
                <InputItem
                  label="City"
                  type="text"
                  icon={CityIcon}
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
                <InputItem
                  label="Province/State"
                  type="text"
                  icon={StateIcon}
                  onChange={(e) => setLocationState(e.target.value)}
                  value={locationState}
                />
                <InputItem
                  label="Country"
                  type="text"
                  icon={CountryIcon}
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                />
                <CustomSelect
                  options={USER_ROLES}
                  selectedOption={userRole}
                  setSelectedOption={setUserRole}
                  defaultOption="Select User Role"
                  icon={UserRoleIcon}
                />
              </div>
              {userRole === adminRoleValue && (
                <div className="permissions">
                  <span>
                    Admin can add up to{' '}
                    <input type="number" value={usersLimit} onChange={(e) => setUsersLimit(e.target.value)} /> users
                    under his management
                  </span>
                  <Form.Check
                    type="switch"
                    id="topics-permission"
                    label="Admin can assign/delete Topics for a user"
                    checked={topicsPermission}
                    onChange={() => setTopicsPermission((prev) => !prev)}
                  />
                  <Form.Check
                    type="switch"
                    id="groups-permission"
                    label="Admin can assign/delete Groups for a user"
                    checked={groupsPermission}
                    onChange={() => setGroupsPermission((prev) => !prev)}
                  />
                  <Form.Check
                    type="switch"
                    id="trends-permission"
                    label="Admin can assign/delete Google Trend Topics for a user"
                    checked={trendsPermission}
                    onChange={() => setTrendsPermission((prev) => !prev)}
                  />
                  <Form.Check
                    type="switch"
                    id="district-permission"
                    label="Admin can assign/delete Electoral District (Elections + Maps) for a user"
                    checked={districtPermission}
                    onChange={() => setDistrictPermission((prev) => !prev)}
                  />
                  <Form.Check
                    type="switch"
                    id="trends-permission"
                    label="Admin can turn on/off MarketPulse for a user"
                    checked={mpPermission}
                    onChange={() => setMpPermission((prev) => !prev)}
                  />
                </div>
              )}
              <div className="submit">
                <FormButton
                  subLink={'Cancel'}
                  subLinkClick={onCloseHandler}
                  text={userData ? 'Save' : 'Create'}
                  onClick={userData ? handleSave : handleCreate}
                />
              </div>
            </>
          ) : (
            <Fader size={50} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserAdd;
