import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Table from '../../components/Table/Table';
import UserProfile from '../../components/UserProfile/UserProfile';
import UserAdd from '../../components/UserAdd/UserAdd';
import Fader from '../../components/Loaders/Fader';
import TagList from '../TagList/TagList';
import MPSwitcher from '../../components/MPSwitcher/MPSwitcher';

import { getUserInfo, getAllUsers, assignTopics, getAdminPermissions } from '../../../http/usersAPI';
import { getTopics } from '../../../http/dataAPI';
import { assignDistrict, getAllDistricts } from '../../../http/districtAPI';
import { USER_ROLES, USER_TABLE_HEADER } from '../../../util/constants/AppConstants';

import PencilIcon from '../../../assets/images/icons/pencil.svg';
import './Users.scss';

const Users = () => {
  const config = useSelector((state) => state.config.config);
  const backgroundEffectState = useSelector((state) => state.settings.backgroundEffect);

  const [backgroundEffectChecked, setBackgroundEffectChecked] = useState(backgroundEffectState);

  const [dataPreviewLoading, setDataPreviewLoading] = useState(true);
  const [userData, setUserData] = useState([]);

  const [activeNameList, setActiveNameList] = useState([]);
  const [activeUserId, setActiveUserId] = useState(false);

  const [isAddUser, setIsAddUser] = useState(false);

  const [activeUserProfileData, setActiveUserProfileData] = useState(null);
  const [activeUserProfileLoading, setActiveUserProfileLoading] = useState(false);
  const [nameData, setNameData] = useState(false);
  const [activeDistrict, setActiveDistrict] = useState(null);

  const [isTaggingActive, setIsTaggingActive] = useState(false);
  const [taggingType, setTaggingType] = useState('');

  const [districts, setDistricts] = useState([]);

  const adminRoleValue = USER_ROLES.find((role) => role.short === 'Admin').value;

  const handleUserStatus = (status) => {
    return <span className={`status-label ${status === 2 ? '' : 'inactive'}`}></span>;
  };

  const addItem = (id, type, activeItem, setActiveItem) => {
    setTaggingType(type);
    setIsTaggingActive(true);
    setActiveItem(activeItem);
    setActiveUserId(id);
  };

  const handleUserSearches = (searches, userId) => {
    return searches.map((search) => {
      if (search === '+') {
        return (
          <span className="search-label add" onClick={() => addItem(userId, 'searches', searches, setActiveNameList)}>
            +
          </span>
        );
      }
      return <span className="search-label">{search.search}</span>;
    });
  };

  const handleRenderDistrict = (district, userId) => {
    return (
      <>
        {district && <span className="search-label">{district.name}</span>}
        <span className="search-label add" onClick={() => addItem(userId, 'districts', district, setActiveDistrict)}>
          {district ? <img src={PencilIcon} /> : '+'}
        </span>
      </>
    );
  };

  const handleSetUserData = (userdata) => {
    const userArr = userdata.map(({ id, firstname, lastname, email, status, searches, district, marketpulse }) => ({
      id,
      firstname,
      lastname,
      email,
      status: handleUserStatus(status),
      searches: handleUserSearches([...searches, '+'], id),
      district: handleRenderDistrict(district, id),
      marketpulse: <MPSwitcher enabled={marketpulse} userId={id} />,
    }));
    setUserData(userArr);
  };

  const handleGetUsersData = async () => {
    const res = await getAllUsers(config);

    if (res.status === 200) {
      handleSetUserData(res.data.users);
      setDataPreviewLoading(false);
    } else {
      console.log('ERROR: Grabbing Users');
    }
  };

  const handleGetDistrictsData = async () => {
    const res = await getAllDistricts(config);

    if (res.status === 200) {
      setDistricts(res.data.districts);
      setDataPreviewLoading(false);
    } else {
      console.log('ERROR: Grabbing Districts');
    }
  };

  const handleUserClick = async (id) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setActiveUserProfileLoading(true);

    const res = await getUserInfo(config, id);

    if (res.status === 'success') {
      setDataPreviewLoading(false);

      if (res.data.role === adminRoleValue) {
        const res2 = await getAdminPermissions(config, id);
        if (res2.status === 200) {
          setActiveUserProfileData({ ...res.data, permissions: res2.data.permissions });
        } else {
          setActiveUserProfileData(res.data);
          console.log('ERROR: Grabbing Topics');
        }
      } else {
        setActiveUserProfileData(res.data);
      }

      setActiveUserProfileLoading(false);
    } else {
      console.log('ERROR: Grabbing Users');
    }
  };

  const handleGetNameData = async () => {
    const res = await getTopics(config);

    if (res.status === 200) {
      setNameData(res.data);
    } else {
      console.log('ERROR: Grabbing Topics');
    }
  };

  const userTableColumns = useMemo(() => USER_TABLE_HEADER, []);

  const handleUserCreate = () => {
    setIsAddUser(false);
    handleGetUsersData();
  };

  const handleUserUpdate = (id) => {
    handleGetUsersData();
    handleUserClick(id);
  };

  const handleDeleteSuccess = () => {
    setActiveUserProfileData(null);
    handleGetUsersData();
  };

  const handleTagsCancel = () => {
    setIsTaggingActive(false);
    setActiveUserId(null);
  };

  useEffect(() => {
    if (dataPreviewLoading && config.api) {
      handleGetUsersData();
      handleGetNameData();
      handleGetDistrictsData();
    }
  }, [dataPreviewLoading, config]);

  useEffect(() => {
    setBackgroundEffectChecked(backgroundEffectState);
  }, [backgroundEffectState]);

  return (
    <div className="dashboard-components">
      <div className="dc-left full">
        <div className="sentiment-trend chart-component user-component">
          {activeUserProfileLoading ? (
            <Fader />
          ) : activeUserProfileData ? (
            <UserProfile
              data={activeUserProfileData}
              onSaveHandler={(id) => handleUserUpdate(id)}
              onDeleteHandler={handleDeleteSuccess}
            />
          ) : (
            'Please Select a User From the List Below'
          )}
        </div>

        <div className="sentiment-trend chart-component">
          <div className="user-manager">
            <div className="edit-btn" onClick={() => setIsAddUser(true)}>
              Add New
            </div>
          </div>

          {!dataPreviewLoading ? (
            <Table
              columns={userTableColumns}
              data={userData}
              clickHandler={handleUserClick}
              clickHandlerTriggers={['firstname-th', 'lastname-th', 'email-th']}
              searchLabel="Search for users"
            />
          ) : (
            <div className="sentiment-charts">
              <Fader />
            </div>
          )}
        </div>

        {isAddUser && (
          <UserAdd isOpen={isAddUser} onSaveHandler={handleUserCreate} onCloseHandler={() => setIsAddUser(false)} />
        )}
        {isTaggingActive && (
          <TagList
            isOpen={isTaggingActive}
            taggingType={taggingType}
            taggingList={taggingType === 'searches' ? nameData : districts}
            startTaggingList={taggingType === 'searches' ? activeNameList : activeDistrict ? [activeDistrict] : []}
            handleTagsCancel={handleTagsCancel}
            activeItemId={activeUserId}
            handleGetItemsData={handleGetUsersData}
            setIsTaggingActive={setIsTaggingActive}
            request={taggingType === 'searches' ? assignTopics : assignDistrict}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
